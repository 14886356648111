import React from 'react';
import styled from 'styled-components';

const Tile = styled.div`
  margin: 2rem 0rem 2rem 0rem;
  width: 100%;
  text-align: center;

  & > div {
    display: inline-block;
  }

  & > svg {
    text-align: center;
  }
  min-height: 10rem;
`;

const svgDimension = 204
const IndexTile = ({ light, children }) => (
  <Tile>
    <div>
      <svg 
        height={svgDimension}
        width={svgDimension}
      >
      <circle 
        cx= "100" 
        cy="100"
        r="98" 
        strokeWidth="5" 
        strokeStyle="dashed"
        strokeDasharray="10 5"
        stroke={"gray"}
        fill={"lightgray"}
      />
    </svg>
    {children}
  </div>
  </Tile>
);

export default IndexTile;
