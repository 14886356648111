import React from 'react'
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { 
  LonelyBlue, 
  YellowHand,
  StationaryPinkRed
} from '../components/colors';
import Layout from '../layouts';
import IndexTile from '../components/IndexTile';

const commonBorderlandsFont = css`
  // background-color: ${YellowHand};
  text-decoration: none;
  text-shadow: 2px 5px ${LonelyBlue};
  -webkit-text-stroke: 1px black;
  text-shadow: 2px 5px black;
  font-weight: lighter;
  font-family: "Compacta Bold", Courier;
  font-size: ${isLarge => isLarge ? '4rem' : '2rem'};
  &:hover {
    color: ${StationaryPinkRed};
  }
`;

const StyledIndexLink = styled(Link)`
  ${commonBorderlandsFont};
`;

const Container = styled.div`
  min-height: 100%;
  margin-top: 1rem;

  & > p {
    // color: ${LonelyBlue};
    margin-top: 1rem;
    padding: .5rem;
    border-radius: 3px;
  }

  & div
`;

const LinkWrapper = styled.div`
  // margin-left: .5rem;
  margin-top: -7rem;
`;

const IndexPage = ({ data }) => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad'
    }
    }
    }}>
    <Container className="pure-g">
      <div>
        <IndexTile light={true}>
          <LinkWrapper>
            <StyledIndexLink 
              to={'/music'}
            >
              music
            </StyledIndexLink>
          </LinkWrapper>
        </IndexTile>
      </div>
      <div>
        <IndexTile light={true}>
          <LinkWrapper>
            <StyledIndexLink 
              to={'/work'}
            >
                work
            </StyledIndexLink>
          </LinkWrapper>
        </IndexTile>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
